import type { Ref } from "react";
import React, { forwardRef } from "react";
import clsx from "clsx";

import { Icon, type Icons } from "@sunrise/icons";

import styles from "./button.module.css";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: "filled" | "outlined" | "light" | "tonal" | "none";
  icon?: Icons;
  label?: string;
  children?: React.ReactNode;
} & CommonProps;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { icon, children, label, variant = "outlined", ...props }: ButtonProps,
  ref: Ref<HTMLButtonElement>,
) {
  const className = clsx(
    variant !== "none" && [
      styles.button,
      styles[variant],
      icon && styles.withIcon,
      icon && styles.iconOnly,
    ],
    props.className,
  );

  const contents = (
    <>
      {icon && (
        <Icon className={styles.icon} data-testid="button.icon" name={icon} />
      )}
      {children}
    </>
  );

  return (
    <button
      {...props}
      ref={ref}
      className={className}
      data-testid="button"
      title={label}
    >
      {contents}
    </button>
  );
});

export { Button };
