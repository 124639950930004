import type { ReactElement } from "react";
import { clsx } from "clsx";
import YalloLogo from "static/images/logo.svg";

import { useTranslator } from "@sunrise/translator";
import { usePlayerPlayingInfo } from "@sunrise/yallo-player-controls";

import { YALLO_TV_URL } from "@/core/constants";

import styles from "./player-info.module.css";

export function PlayerInfo({ className }: CommonProps): ReactElement {
  const t = useTranslator();
  const { title, channelLogo, status, isLive, schedule, subtitle } =
    usePlayerPlayingInfo(t);

  // TODO: replace title and subtitle with ProgramTitleAndSubtitle component

  return (
    <a
      className={clsx([styles.container, className])}
      href={YALLO_TV_URL}
      rel="noreferrer"
      target="_blank"
    >
      <div className={styles.info}>
        {!!title && <h1 className={styles.title}>{title}</h1>}
        {!!subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
        <div className={styles.schedule}>
          {channelLogo ? (
            <img
              alt="channel logo"
              className={styles.channelLogo}
              src={channelLogo}
            />
          ) : null}
          <p className={styles.statusSchedule}>
            {status ? (
              <span
                className={clsx({
                  [`${styles.liveIndicator}`]: isLive,
                })}
              >
                {status}
              </span>
            ) : undefined}
            {schedule.formattedStartEnd}
          </p>
        </div>
      </div>
      <div className={styles.logo}>
        <YalloLogo className={styles.yalloLogo} />
      </div>
    </a>
  );
}
