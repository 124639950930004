import {
  actionPlayerShouldPlay,
  getVideoElement,
  playerAtom,
  playerShouldDetachAtom,
} from "@sunrise/player";
import type { Store } from "@sunrise/store";
import { deviceInfo } from "@sunrise/utils";

/**
 * This function tries to fix the initial delay on the HTML 5 player and keep the player on live if it was paused on the native overlay.
 * @param store
 */
export function initPreventSeeking(store: Store): void {
  // NOTE: only needed for the HTML 5 player
  const isNativeHls = deviceInfo.isSafari || deviceInfo.isIOS;
  if (!isNativeHls) return;

  // NOTE: we don't want this on desktop
  if (!deviceInfo.isMobileOrTablet) return;

  const videoElement = getVideoElement();

  let seeked = false;
  let lifecycle: "setLive" | "setLiveSeeked" | null = null;

  const setLive = (): void => {
    videoElement.currentTime = Number.MAX_SAFE_INTEGER;
    lifecycle = "setLive";
  };

  const shouldDetach = (): boolean => {
    return store.get(playerShouldDetachAtom);
  };

  const onPlaying = (): void => {
    if (shouldDetach()) return;

    if (videoElement.seeking) return;
    if (lifecycle === "setLive") return;

    if (seeked) {
      seeked = false;
      setLive();
      return;
    }

    lifecycle = null;
  };

  videoElement.addEventListener("seeked", () => {
    if (lifecycle === "setLive") {
      lifecycle = "setLiveSeeked";
    }

    if (!lifecycle) {
      seeked = true;
    }

    onPlaying();
  });

  videoElement.addEventListener("playing", onPlaying);

  videoElement.addEventListener("play", async () => {
    if (shouldDetach()) return;

    if (store.get(playerAtom).shouldBePaused) {
      store.set(playerAtom, actionPlayerShouldPlay());
    }

    if (videoElement.seeking) return;

    if (!lifecycle) {
      setLive();
    }
  });
}
