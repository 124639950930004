import { type ReactNode, Suspense } from "react";
import { useAtomValue } from "jotai";

import { skipAd, videoAdsAtom } from "@sunrise/ads";
import { selectPlayerIsLoading } from "@sunrise/player";
import { shouldNotShowPlayerControlsAtom } from "@sunrise/yallo-player-controls";
import { Spinner } from "@sunrise/yallo-web-components";

import { Ads } from "@/features/ads/ads";
import { ErrorOverlay } from "@/features/error/error-overlay";
import { PlayOverlay } from "@/features/player/play-overlay";
import { showShowPlayOverlay } from "@/features/player/play-overlay.atom";
import { PlayerUi } from "@/features/player/player-ui";
import { useAutoreload } from "@/modules/background/use-autoreload";

import styles from "./player.module.css";

export default function Player(): ReactNode {
  // take care of backgrounding
  useAutoreload();

  const loading = useAtomValue(selectPlayerIsLoading);
  const shouldNotShowPlayerControls = useAtomValue(
    shouldNotShowPlayerControlsAtom,
  );
  const showPlayOverlay = useAtomValue(showShowPlayOverlay);
  const { isPlaying: isPlayingAds } = useAtomValue(videoAdsAtom);

  return (
    <main className={styles.root}>
      <Suspense
        fallback={
          <div className={styles.spinner}>
            <Spinner isActive />
          </div>
        }
      >
        <div className={styles.wrapper}>
          <ErrorOverlay />

          {isPlayingAds && (
            <Ads className={styles.adsOverlay} skipAd={skipAd} />
          )}

          {showPlayOverlay ? (
            <PlayOverlay />
          ) : (
            <div className={styles.inner}>
              {loading && (
                <div className={styles.spinner}>
                  <Spinner isActive />
                </div>
              )}
              {!shouldNotShowPlayerControls && <PlayerUi />}
            </div>
          )}
        </div>
      </Suspense>
    </main>
  );
}
