import type { ReactElement } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai";

import { selectPlayerIsPlaying } from "@sunrise/player";
import { useIdle } from "@sunrise/utils";

import { PlayerControls } from "@/features/player-controls/player-controls";
import { PlayerInfo } from "@/features/player-controls/player-info";

import styles from "./player-ui.module.css";

const PLAYER_AUTO_HIDE_CONTROLS_TIMEOUT_IN_MS = 3_000;

export function PlayerUi(): ReactElement {
  const idle = useIdle(PLAYER_AUTO_HIDE_CONTROLS_TIMEOUT_IN_MS);
  const isPlaying = useAtomValue(selectPlayerIsPlaying);

  const showControls = !idle || !isPlaying;

  return (
    <div className={styles.wrapper}>
      <div
        className={clsx(styles.overlay, {
          [styles.visible ?? ""]: showControls,
        })}
      >
        <PlayerInfo className={styles.playerInfo} />
        <PlayerControls className={styles.controls} />
      </div>
    </div>
  );
}
