/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** VendorSchema */
export interface VendorSchema {
  /**  Id */
  _id: string;
  /** Name */
  name: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  embeddedPlayer = {
    /**
     * No description
     *
     * @tags vendors
     * @name DetVendorEmbeddedPlayerV1VendorsVendorIdGet
     * @summary Det Vendor
     * @request GET:/embedded-player/v1/vendors/{vendor_id}
     */
    detVendorEmbeddedPlayerV1VendorsVendorIdGet: (vendorId: string, params: RequestParams = {}) =>
      this.http.request<VendorSchema, void | HTTPValidationError>({
        path: `/embedded-player/v1/vendors/${vendorId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthEmbeddedPlayerV1HealthGet
     * @summary Health
     * @request GET:/embedded-player/v1/health
     */
    healthEmbeddedPlayerV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/embedded-player/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootEmbeddedPlayerV1Get
     * @summary Root
     * @request GET:/embedded-player/v1
     */
    rootEmbeddedPlayerV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/embedded-player/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
