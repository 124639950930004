import type { ReactElement, ReactNode } from "react";
import { createRoot } from "react-dom/client";
import type { QueryClient } from "@tanstack/query-core";
import { atom, useAtomValue } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { initPauseAds, initVideoAds } from "@sunrise/ads";
import { playerAnalyticsRunEffect } from "@sunrise/analytics";
import { deviceTypeAtom, fetchRefreshAuthTokens } from "@sunrise/auth";
import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { ngHttpClientConfigAtom } from "@sunrise/backend-ng-core";
import type { Language } from "@sunrise/backend-types-core";
import type { BaseError } from "@sunrise/error";
import { errorAtom } from "@sunrise/error";
import {
  createPrivateApi,
  hostsAtom,
  httpClientAtom,
  publicApi,
} from "@sunrise/http-client";
import {
  currentLanguageAtom,
  useBindDateFnsLocaleToLanguageAtom,
} from "@sunrise/i18n";
import {
  actionJWTClear,
  actionJWTSetTokens,
  jwtAtom,
  selectAccessToken,
  selectRefreshToken,
} from "@sunrise/jwt";
import { initMonitoring } from "@sunrise/monitoring";
import {
  getVideoElement,
  initVideoPlayer,
  playerDelayedBufferSettingsAtom,
  playerLiveBufferSettingsAtom,
} from "@sunrise/player";
import {
  actionPlayerStatsSetEnabled,
  playerStatsAtom,
} from "@sunrise/player-stats";
import { type Store, StoreProvider } from "@sunrise/store";
import {
  getTranslationFileAtom,
  TranslationProvider,
} from "@sunrise/translator";
import type { Nullable } from "@sunrise/utils";
import { initPlayerManager } from "@sunrise/yallo-common-player-manager";
import { initFullscreenManager } from "@sunrise/yallo-player-controls";
import { appVersionAtom } from "@sunrise/yallo-settings";
import { isNotKnownUserError } from "@sunrise/yallo-user";
import {
  deviceIdAtom,
  getClientID,
  legacySocketUrlAtom,
  userAgentAtom,
} from "@sunrise/yallo-websocket";

import "@sunrise/web-common-styles/base.css";
import "@sunrise/web-common-styles/reset.css";
import "@/styles/fonts.css";
import "@/styles/global.css";

import { yalloStore } from "@/core";
import { getTranslationFile } from "@/modules/i18n/get-translation-file";
import { initPreventSeeking } from "@/modules/player/init-prevent-seeking";
import {
  HydrateQueryAtoms,
  queryClient,
  QueryProvider,
} from "@/modules/query-provider";
import Player from "@/routes/player";

import { getDeviceType } from "./core/get-device-type";

const environment = import.meta.env.MODE;
const appVersion = import.meta.env.VITE_APP_VERSION;
const isProdMode = environment === "production";

// TODO: Read value from env variable defined on AWS
// We fallback to null when the value is falsey ("") for example.
const dsn = import.meta.env.VITE_SENTRY_DSN || null;

initMonitoring(
  {
    dsn,
    environment,
    isProdMode,
  },
  {
    initialScope: (scope) => {
      return scope;
    },
  },
);

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_ENDPOINT;

const NOOP = () => {
  /* noop */
};

/**
 * Initialize libraries
 */
function initIntegrations(): void {
  // NOTE: This is a temporary solution to avoid having a refresh from ng backend and a refresh from legacy backend at the same time.
  let refreshPromise: Nullable<ReturnType<typeof fetchRefreshAuthTokens>>;
  const doRefreshTokens = async (refreshToken: string) => {
    const host = yalloStore.get(hostsAtom).api;
    if (!host) {
      throw new Error("No host found");
    }

    if (!refreshPromise) {
      refreshPromise = fetchRefreshAuthTokens(
        host,
        refreshToken,
        !yalloStore.get(isLegacyBackendAtom),
      );
    }

    const result = await refreshPromise;
    refreshPromise = null;
    return result;
  };

  yalloStore.set(deviceTypeAtom, getDeviceType());

  const ngBaseUrl = import.meta.env.VITE_NG_API_ENDPOINT;
  if (ngBaseUrl) {
    yalloStore.set(ngHttpClientConfigAtom, {
      // The real config.
      baseUrl: ngBaseUrl,
      // Stuff for the error interceptor.
      doRefreshTokens,
      isNotKnownUserError,
      getAccessToken: () => yalloStore.get(selectAccessToken),
      getRefreshToken: () => selectRefreshToken(yalloStore.get(jwtAtom)),
      setTokens: (accessToken, refreshToken, wsToken) => {
        yalloStore.set(
          jwtAtom,
          actionJWTSetTokens({ accessToken, refreshToken, wsToken }),
        );
      },
      resetTokens: (error: BaseError) => {
        yalloStore.set(errorAtom, error);
        yalloStore.set(jwtAtom, actionJWTClear());
      },
      getLanguage: () => yalloStore.get(currentLanguageAtom),
    });
  }

  yalloStore.set(httpClientAtom, {
    privateApi: createPrivateApi(
      yalloStore,
      doRefreshTokens,
      undefined,
      undefined,
      isNotKnownUserError,
    ),
    publicApi,
  });
  yalloStore.set(hostsAtom, {
    api: import.meta.env.VITE_API_ENDPOINT,
    clients: import.meta.env.VITE_CLIENTS_ENDPOINT,
    data: import.meta.env.VITE_DATA_ENDPOINT,
  });

  // We need to configure the socket URL so that the socket can kick in as needed.
  if (WEBSOCKET_URL) {
    yalloStore.set(legacySocketUrlAtom, WEBSOCKET_URL);
  }

  const clientId = getClientID("client_id");
  if (clientId) {
    yalloStore.set(deviceIdAtom, atom(Promise.resolve(clientId)));
  }
  yalloStore.set(appVersionAtom, import.meta.env.VITE_APP_VERSION);
  yalloStore.set(userAgentAtom, window.navigator.userAgent);

  yalloStore.set(getTranslationFileAtom, { fn: getTranslationFile });

  initPlayerManager(yalloStore, import.meta.env.MODE === "production");
  initVideoAds(
    yalloStore,
    getVideoElement,
    NOOP,
    import.meta.env.MODE !== "production",
  );
  initPauseAds(yalloStore);
  initVideoPlayer(
    yalloStore,
    {
      showPlayer: NOOP,
      getPlayerBufferSettings: {
        live: () => yalloStore.get(playerLiveBufferSettingsAtom),
        delayed: () => yalloStore.get(playerDelayedBufferSettingsAtom),
      },
      // Make sure to inject the player error into the regular error atom so we can catch it before the ErrorBoundary.
      onError: (e) => yalloStore.set(errorAtom, e),
      isEnabled: !window.disablePlayer,
    },
    (playerController) => {
      // eslint-disable-next-line no-console
      console.log("Using", playerController.getPlayerWrapperName());
    },
  );

  initPreventSeeking(yalloStore);

  if (import.meta.env.MODE === "test") {
    yalloStore.set(currentLanguageAtom, "de" as Language);
  }

  // For testing purposes
  if (
    import.meta.env.MODE !== "production" &&
    import.meta.env.VITE_PLAYER_STATS_DEFAULT === "true"
  ) {
    yalloStore.set(playerStatsAtom, actionPlayerStatsSetEnabled());
  }

  initFullscreenManager(yalloStore);
}

function logVersion(): void {
  if (!import.meta.env.PROD) {
    return;
  }

  console.info(`mode: ${import.meta.env.MODE}
version: ${appVersion}
commit: ${import.meta.env.VITE_APP_COMMIT_HASH}
build: ${import.meta.env.VITE_APP_BUILD_NR} ${
    import.meta.env.VITE_APP_BUILD_DATE
      ? `(${new Date(
          Number(import.meta.env.VITE_APP_BUILD_DATE),
        ).toLocaleString()})`
      : ""
  }`);
}

window.addEventListener("DOMContentLoaded", () => {
  logVersion();

  const appNode = document.getElementById("app");
  if (isNil(appNode)) throw new Error("App element does not exist");

  initIntegrations();

  createRoot(appNode).render(
    <AppProvider
      enableDevTools={!import.meta.env.PROD}
      queryClient={queryClient}
      queryClientAtom={queryClientAtom}
      storeInstance={yalloStore}
    >
      <Root />
    </AppProvider>,
  );
});

export function AppProvider(props: {
  children: ReactNode;
  queryClient: QueryClient;
  enableDevTools: boolean;
  queryClientAtom: typeof queryClientAtom;
  storeInstance: Store;
}): ReactNode {
  return (
    <QueryProvider
      enableDevTools={props.enableDevTools}
      queryClientInstance={props.queryClient}
    >
      <StoreProvider enableDevTools={props.enableDevTools} store={yalloStore}>
        <HydrateQueryAtoms queryClientAtom={props.queryClientAtom}>
          <TranslationProvider>{props.children}</TranslationProvider>
        </HydrateQueryAtoms>
      </StoreProvider>
    </QueryProvider>
  );
}

function Root(): ReactElement {
  // Need to listen to the run effect for the player analytics to kick in.
  useAtomValue(playerAnalyticsRunEffect);
  useBindDateFnsLocaleToLanguageAtom();

  return <Player />;
}
