import type { ReactNode } from "react";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider, useQueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useHydrateAtoms } from "jotai/react/utils";
import type { queryClientAtom as qca } from "jotai-tanstack-query";

import { BaseError } from "@sunrise/error";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error): boolean {
        if (failureCount >= 2) {
          return false;
        }

        if (error instanceof BaseError) {
          return error.shouldRetry;
        }

        return true;
      },
    },
  },
});

type QueryProviderProps = {
  children: ReactNode;
  queryClientInstance: QueryClient;
  enableDevTools?: boolean;
};

export function QueryProvider({
  children,
  queryClientInstance,
  enableDevTools = false,
}: QueryProviderProps): JSX.Element {
  return (
    <QueryClientProvider client={queryClientInstance}>
      {enableDevTools ? <ReactQueryDevtools initialIsOpen={false} /> : null}
      {children}
    </QueryClientProvider>
  );
}

type HydrateAtomsProps = {
  children: ReactNode;
  queryClientAtom: typeof qca;
};

export function HydrateQueryAtoms({
  children,
  queryClientAtom,
}: HydrateAtomsProps): ReactNode {
  const queryClientInstance = useQueryClient();
  useHydrateAtoms([[queryClientAtom, queryClientInstance]]);
  return children;
}
