import { isAxiosError } from "axios";
import deepEqual from "fast-deep-equal";
import { atomFamily } from "jotai/utils";
import { atomWithQuery } from "jotai-tanstack-query";

import {
  ngEmbeddedPlayerApiAtom,
  type VendorSchema,
} from "@sunrise/backend-ng-embedded-player";
import { canSwallowError } from "@sunrise/error";
import type { Nullable } from "@sunrise/utils";

import { VendorError } from "@/modules/vendor/vendor.error";

export const vendorQueryAtom = atomFamily(
  ({
    id,
    secret,
    host: vendorHost,
  }: {
    id: Nullable<string>;
    secret: Nullable<string>;
    host: string;
  }) =>
    atomWithQuery<Nullable<VendorSchema>>((get) => {
      const api = get(ngEmbeddedPlayerApiAtom);

      return {
        queryKey: ["vendors", id],
        queryFn: async () => {
          if (!id || !secret) {
            const err = new VendorError("id/secret missing");
            console.error(err);
            throw err;
          }

          try {
            const result =
              await api.embeddedPlayer.detVendorEmbeddedPlayerV1VendorsVendorIdGet(
                id,
                {
                  headers: {
                    "x-vendor-host": vendorHost,
                    "x-vendor-secret": secret,
                  },
                },
              );

            return result.data;
          } catch (e) {
            if (isAxiosError(e)) {
              throw new VendorError(e.message, { cause: e });
            }

            if (canSwallowError(e)) {
              return null;
            }

            console.error(e);
            throw e;
          }
        },
        retry: false,
        retryOnMount: false,
        suspense: true,
        throwOnError: false,
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: false,
        refetchInterval: false,
      };
    }),
  deepEqual,
);
