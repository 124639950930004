import type { ReactNode } from "react";
import { useCallback, useEffect } from "react";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import adsGradient from "static/gradients/ads-gradient.webp";

import { adControlsHaveFocusAtom, videoAdsUIAtom } from "@sunrise/ads";
import { useTranslator } from "@sunrise/translator";

import styles from "./ads.module.css";
import { SkipButton } from "./skip-button";

type AdsProps = CommonProps & {
  skipAd: () => void;
};

export function Ads({
  skipAd,
  "data-testid": dataTestId = "ads",
  className,
}: AdsProps): ReactNode {
  const t = useTranslator();

  const ui = useAtomValue(videoAdsUIAtom);

  const hasButtons = !!(ui && (ui.why?.visible ?? ui.skip?.visible));

  const setFocused = useAtomCallback(
    useCallback((_, set, value: "self" | "child" | false) => {
      set(adControlsHaveFocusAtom, value === "child");
    }, []),
  );

  useEffect(() => {
    return () => setFocused(false);
  }, [setFocused]);

  if (!ui) {
    return null;
  }

  if (!ui.counter && !ui.message) {
    return null;
  }

  const counter = ui.counter ? (
    <span data-testid={`${dataTestId}.counter`}>
      {t(ui.counter.key, ui.counter.args)}
    </span>
  ) : null;

  // They may not be visible but skip should still be rendered even if it is just available.
  const buttons =
    hasButtons || ui.skip?.available ? (
      <div className={styles.buttons} data-testid={`${dataTestId}.buttons`}>
        {ui.skip?.available ? (
          <SkipButton
            className={styles.skipButton}
            data-testid={`${dataTestId}.skip-button`}
            skipAvailableAfter={ui.skip.countdownInSeconds ?? 0}
            visible={ui.skip.visible}
            onSkip={skipAd}
          />
        ) : null}
      </div>
    ) : null;

  return (
    <div
      className={clsx([styles.root, className])}
      data-testid={dataTestId}
      style={{
        backgroundImage: `url(${adsGradient})`,
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
        backgroundPositionX: "left",
      }}
    >
      <span className={styles.top} data-testid={`${dataTestId}.title`}>
        {ui.message ? t(ui.message) : null}
      </span>
      <div className={styles.bottom}>
        <div className={styles.counter}>{counter}</div>
        {buttons}
      </div>
    </div>
  );
}
