import type { ReactElement } from "react";
import { useCallback } from "react";
import { useAtomValue, useSetAtom } from "jotai";

import {
  actionPlayerShouldPause,
  actionPlayerShouldReportAsStopped,
  actionPlayerToggleFullscreen,
  actionPlayerToggleMute,
  actionPlayerTogglePictureInPicture,
  playerAtom,
  selectPlayerCurrentPlayRequest,
  selectPlayerFullscreenSupportedMode,
  selectPlayerPictureInPictureSupported,
  selectPlayerShouldBeFullscreen,
  selectPlayerShouldBeMuted,
  selectPlayerShouldBePictureInPicture,
  selectPlayerState,
} from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import {
  actionPlayerManagerLoadPlayRequest,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";

import { Button } from "@/components/button";

import styles from "./player-controls.module.css";
import { PlayerSeekbar } from "./player-seekbar";

type PlayerControlsProps = CommonProps;

function PlayerControls({ className }: PlayerControlsProps): ReactElement {
  const t = useTranslator();
  const playerState = useAtomValue(selectPlayerState);
  const dispatchPlayer = useSetAtom(playerAtom);
  const dispatchPlayerManager = useSetAtom(playerManagerAtom);
  const currentPlayRequest = useAtomValue(selectPlayerCurrentPlayRequest);
  const isPlaying = playerState === "playing";
  const isMuted = useAtomValue(selectPlayerShouldBeMuted);
  const isFullscreen = useAtomValue(selectPlayerShouldBeFullscreen);
  const isFullscreenSupported = useAtomValue(
    selectPlayerFullscreenSupportedMode,
  );
  const isPictureInPicture = useAtomValue(selectPlayerShouldBePictureInPicture);
  const isPictureInPictureSupported = useAtomValue(
    selectPlayerPictureInPictureSupported,
  );

  const togglePlayPause = useCallback(() => {
    if (isPlaying) {
      dispatchPlayer(actionPlayerShouldPause());
      dispatchPlayer(actionPlayerShouldReportAsStopped());
    } else {
      if (currentPlayRequest) {
        dispatchPlayerManager(
          actionPlayerManagerLoadPlayRequest(currentPlayRequest, {}),
        );
      }
    }
  }, [currentPlayRequest, dispatchPlayer, dispatchPlayerManager, isPlaying]);

  const toggleMute = useCallback(() => {
    dispatchPlayer(actionPlayerToggleMute());
  }, [dispatchPlayer]);

  const toggleFullscreen = useCallback(() => {
    dispatchPlayer(actionPlayerToggleFullscreen());
  }, [dispatchPlayer]);

  const togglePictureInPicture = useCallback(() => {
    dispatchPlayer(actionPlayerTogglePictureInPicture());
  }, [dispatchPlayer]);

  return (
    <div className={className}>
      <div className={styles.buttons}>
        <Button
          key={t("button_play_pause")}
          icon={isPlaying ? "pause" : "play"}
          variant="light"
          onClick={togglePlayPause}
        />
      </div>
      <div className={styles.seekWrapper}>
        <PlayerSeekbar />
        <div className={styles.actions}>
          <Button
            icon={isMuted ? "volumeOff" : "volumeUp"}
            label={t("more_player_actions_button")}
            variant="light"
            onClick={toggleMute}
          />
          {isPictureInPictureSupported && (
            <Button
              icon={isPictureInPicture ? "pipExit" : "pip"}
              label={t("more_player_actions_button")}
              variant="light"
              onClick={togglePictureInPicture}
            />
          )}
          {!!isFullscreenSupported && (
            <Button
              icon={isFullscreen ? "fullscreenExit" : "fullscreen"}
              label={t("more_player_actions_button")}
              variant="light"
              onClick={toggleFullscreen}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export { PlayerControls };
