import { atom } from "jotai";
import { isNil } from "lodash";

import type { ChannelId } from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";
import { allChannelsAtom } from "@sunrise/yallo-channel-group";

export const queryParamChannelIdAtom = atom(
  async (get): Promise<Nullable<ChannelId>> => {
    const params = new URLSearchParams(window.location.search);
    const channel = params.get("channel");

    // NOTE: in test environments, use default channel and support alias names for easy access
    if (import.meta.env.MODE !== "production") {
      // NOTE: This is not implemented yet on the NG backend.
      const channels = await get(allChannelsAtom);

      if (!channels || !channels[0]) {
        return null;
      }

      const channelId = channel
        ? channels.find((c) => c.aliases?.includes(channel))?.id
        : null;

      if (!isNil(channelId)) {
        return channelId;
      }

      return channels[0].id ?? null;
    }

    return channel as Nullable<ChannelId>;
  },
);
