import type { ReactNode } from "react";
import { useCallback, useState } from "react";
import { useAtomValue } from "jotai/index";
import YalloLogo from "static/images/logo.svg";

import { useAnonymousLogin } from "@sunrise/auth";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { selectPlayerCurrentPlayRequest } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";

import { Button } from "@/components/button";
import { YALLO_TV_URL } from "@/core/constants";
import { queryParamChannelIdAtom } from "@/features/channel/query-param-channel-id.atom";
import { isValidVendorAtom } from "@/modules/vendor";

import styles from "./play-overlay.module.css";

export function PlayOverlay(): ReactNode {
  const isValidVendor = useAtomValue(isValidVendorAtom);
  const { login } = useAnonymousLogin({
    autoLogin: false,
  });
  const isLoggedIn = useAtomValue(selectIsLoggedIn);

  const currentPlayRequest = useAtomValue(selectPlayerCurrentPlayRequest);
  const t = useTranslator();

  const startupChannelId = useAtomValue(queryParamChannelIdAtom);

  const startUpChannel = usePlayChannel({
    channelId: startupChannelId,
  });

  const [loadInProgress, setLoadInProgress] = useState(false);

  const load = useCallback(async (): Promise<void> => {
    if (loadInProgress || !startupChannelId || !isValidVendor) return;

    setLoadInProgress(true);

    try {
      if (!isLoggedIn) {
        await login();
      }

      startUpChannel.play();
    } finally {
      setLoadInProgress(false);
    }
  }, [
    loadInProgress,
    startupChannelId,
    isLoggedIn,
    startUpChannel,
    login,
    isValidVendor,
  ]);

  if (currentPlayRequest) {
    return;
  }

  return (
    <div className={styles.container}>
      {isValidVendor && startupChannelId && (
        <Button
          className={styles.playButton}
          disabled={loadInProgress}
          icon="play"
          label={t("button_play")}
          variant="outlined"
          onClick={load}
        ></Button>
      )}
      <div className={styles.logoContainer}>
        <a href={YALLO_TV_URL} rel="noreferrer" target="_blank">
          <YalloLogo />
        </a>
      </div>
    </div>
  );
}
